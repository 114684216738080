.accommodation_detail {
	.content-holder {
		margin-top: 3rem;
		@include make-row();

		.info,
		.info-extra {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}

		.info {
			z-index: 9;
		}

        .info-extra {
            padding-top: 3rem;
        }

		.gallery-carousel {
			.owl-stage-outer {
				overflow: visible;
				.owl-item {
					opacity: 0;
					visibility: hidden;
					transition: .5s;
					transform: rotate(-15deg);
					&.active {
						transform: rotate(5deg);
						opacity: 1;
						visibility: visible;
					}
					&:hover {
						transform: rotate(0) scale(1.05);
					}
				}
			}
		}

		.persons {
			display: flex;
			margin-bottom: 10px;

			&__icon {
				margin-right: 1rem;
			}
		}

		.action-block {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			max-width: 370px;
			margin: 10px 0;
			padding: 55px 15px 15px;
			background-color: var(--w3-secondary-color);
			border: 2px solid $white;
			border-radius: 10px;
			text-decoration: none;
			color: $white;
			font-weight: 400;
			cursor: pointer;

			.action-block-label {
				flex: 0 0 100%;
				max-width: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				text-align: center;
				font-size: 1.875rem;
				font-weight: 700;
				background-color: var(--w3-primary-color);
				border-radius: 8px 8px 0 0;
			}

			.action-block-text {
				@include make-col(12);
				@extend .pr-0;
				@extend .pr-sm-3;

				@include media-breakpoint-up(sm) {
					@include make-col(6);
				}

				.action-block-title {
					font-size: $font-size-base;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
					color: $white;
				}
			}

			.action-block-prices {
				@include make-col(12);
				@extend .mt-3;
				@extend .pt-3;

				@extend .mt-sm-0;
				@extend .pt-sm-0;

				border-top: 1px solid $white;

				@extend .text-sm-right;

				@include media-breakpoint-up(sm) {
					@include make-col(6);

					border: none;
				}

				.action-block-old-price {
					text-decoration: line-through;
				}
				.action-block-price {
					font-size: $font-size-base * 2.25;
					font-weight: $font-weight-bold;
					color: $white;
				}
			}

            &:hover {
                background-color: $white;
                border-color: $black;
                color: $black;

                .action-block-text {
                    .action-block-title {
                        color: var(--w3-primary-color);
                    }
                }
                .action-block-prices {
                    border-color: $black;
                    .action-block-price {
                        color: var(--w3-primary-color);
                    }
                }

            }
		}
	}
}
