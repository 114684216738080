// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 3rem 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-3rem + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 3rem 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
section.content,
.content-section {
	margin: 3rem 0;
}

// intro-section
// =========================================================================
.intro-section {
	margin: 3rem 0;

	@include media-breakpoint-up(xxxl) {
		margin-top: 6rem;
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 3rem 0;
}

// slider-section
// =========================================================================
.slider-section {
	padding: 3rem 0;
	overflow: hidden;
}

// banner-section
// =========================================================================
// .banner-section {}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 3rem 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 3rem 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding: 5rem 0;
	overflow: hidden;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 3rem 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 3rem 0;
}

&.accommodation_detail {
	section.content {
		overflow: hidden;
	}
}