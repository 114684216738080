// theme setup
@import '~framework/assets/scss/V2.1/0_config/_functions';
@import '~framework/assets/scss/V2.1/0_config/_mixins';
@import '~framework/assets/scss/V2.1/0_config/_custom-variables';
@import './0_config/_variables';
@import '~framework/assets/scss/V2.1/0_config/_variables';
@import '~framework/assets/scss/V2.1/1_vendor/bootstrap';

// or manually select bootstrap components:
/*
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
//@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
//@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
//@import '~bootstrap/scss/badge';
//@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
//@import '~bootstrap/scss/tooltip';
//@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';
*/


// force html font size (prevent tommybooking conflict)
html {
	@import "~framework/assets/scss/V2.1/5_utilities/reset";
	scroll-padding-top: 200px;
}

// theme within body class
body {
	// 2_layout
	@import "~framework/assets/scss/V2.1/2_layout/containers";

	// 3_components
	@import "~framework/assets/scss/V2.1/3_components/alert";
	@import "~framework/assets/scss/V2.1/3_components/buttons";
	@import "~framework/assets/scss/V2.1/3_components/card";
	@import "~framework/assets/scss/V2.1/3_components/carousel";
	@import "~framework/assets/scss/V2.1/3_components/cookie-consent";
	@import "~framework/assets/scss/V2.1/3_components/faq";
	@import "~framework/assets/scss/V2.1/3_components/form";
	@import "~framework/assets/scss/V2.1/3_components/lists";
	@import "~framework/assets/scss/V2.1/3_components/loading";
	@import "~framework/assets/scss/V2.1/3_components/recaptcha";
	@import "~framework/assets/scss/V2.1/3_components/timeslot";

	// 4_page_block
	@import '~framework/assets/scss/V2.1/4_page_block/_all';

	// 5_utilities
	@import "~framework/assets/scss/V2.1/5_utilities/animations";
	@import "~framework/assets/scss/V2.1/5_utilities/contextual";
	@import "~framework/assets/scss/V2.1/5_utilities/sticky";

	// app styling
	@import "./2_layout/base";
	@import "./2_layout/eyecatcher";
	@import "./2_layout/footer";
	@import "./2_layout/header";
	@import "./2_layout/mini-sab";
	@import "./2_layout/sections";

	@import "./3_components/lists";
	@import "./3_components/link-box";
	@import "./3_components/accommodation-list";
	@import "./3_components/slider-card";
	@import "./3_components/characteristics";
	@import "./3_components/cards";
	@import "./3_components/gallery";
	@import "./3_components/nieuwsbrief";
	@import "./3_components/buttons";

	@import "./4_page_block/slider-block";
	@import "./4_page_block/gallery-slider";
	@import "./4_page_block/contentblock";

	@import "./5_pages/privacyverklaring";
	@import "./5_pages/contact";
	@import "./5_pages/faq";
	@import "./5_pages/accommodation_detail";
	@import "./5_pages/wizard";
	@import "./5_pages/service_overview";
	@import "./5_pages/bundle-detail";
	@import "./5_pages/landings_page";
	@import "./5_pages/timeslot_overview";
	@import "./5_pages/bundle-overview";

	// @import "./6_tommy/tommy.book";
	// @import "./6_tommy/tommy.matrix";
	// @import "./6_tommy/tommy.mini-search-book";
	// @import "./6_tommy/tommy.search-book";

	@import "./7_search_book_widget/global-theme";
	@import "./7_search_book_widget/mini-search-book-widget";
	@import "./7_search_book_widget/searchbook-widget";
	@import "./7_search_book_widget/matrix";
	@import "./7_search_book_widget/lastminute-widget";
}
