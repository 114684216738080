.gallery {
    margin: 0;
    .gallery-item {
        padding: 0 3px;
         // rotation
        &:nth-child(2n +1) {
            transform: rotate(-5deg);
        }
        &:nth-child(2n +2) {
            transform: translateY(1rem) rotate(5deg);
        }
        .gallery-link {
            padding: 7px 5px;
            box-shadow: 0 0 20px rgba($black, .3);
            background-color: $white;
        }
        &.large-item {
            transform: rotate(5deg);
            margin-bottom: 2.5rem;
            .gallery-link  {
                padding: 1rem 10px;
                @include media-breakpoint-down(md) {
                    padding: 10px;
                }
            }
        }

        &:hover {
            transform: scale(1.05);
            .gallery-img {
                transform: none;
            }
        }
    }
}