.owl-carousel:not(.eyecatcher-owl-carousel) {
	z-index: 0;
	.item {
		padding: 1rem 10px;
		box-shadow: 0 0 20px rgba($black, 0.3);
		background-color: $white;
		min-height: unset;
		@include media-breakpoint-down(md) {
			padding: 10px;
		}
		.card {
			.card-image {
				// background: transparent
				// 	linear-gradient(rgba($black, 0) 0%, rgba($black, 0.4) 100%)
				// 	0% 0% no-repeat padding-box;
				flex: 1 1 auto;
				img {
					height: 100%;
				}
			}

			.card-img-overlay {
				background: transparent;
				display: flex;
				flex-flow: row nowrap;

				.card-caption {
					align-self: flex-end;

					.card-title {
						margin: 0;
						font-size: 1.5rem;
						&:hover {
							color: $white;
						}
					}
				}
			}
		}
	}
}

.grid-slider {
	.owl-carousel.slider {
		margin-bottom: 1rem;
		.item {
			padding: 1rem;
			box-shadow: none;
			background-color: unset;
		}
	}
}
