&.contact-page {

    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    table {

        tr {
            &:not(:last-of-type) {
                border-bottom: 1px solid $gray;
            }
            td {
                vertical-align: baseline;
                padding: 10px 0;
                
                p {
                    margin-bottom: 5px;
                }
            }
        }

        &.tel-table {
            tr {
                td:nth-child(2) {
                    text-wrap: nowrap;
                    text-align: right;
                }
            }
        }
    }
}