.characteristics-holder {
	padding: 3rem 0;

	//: $gray;
	.characteristics {
        @extend .box-style;
        display: flex;
        flex-wrap: wrap;

		.characteristics-category {
			@include make-col-ready();
			@include make-col(12);

			margin: 0 0 15px 0;

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(md) {
				@include make-col(6);
			}

			@include media-breakpoint-up(xl) {
				@include make-col(4);
			}

			.characteristics-category-title {
				display: block;
				margin: 0 0 10px 0;

				font-size: $font-size-base * 1.125;
				font-weight: $font-weight-bold;
				font-weight: $font-weight-bold;
			}

			.characteristics-list {
				@extend .m-0;
				@extend .p-0;
				list-style: none;

				.characteristics-list-item {
					@extend .position-relative;
					padding: 0 0 0 30px;

					&::before {
						@extend .position-absolute;
						left: 0;

						@extend .d-block;
						height: 24px;

						font-family: "Font Awesome 5 Pro";
						font-size: $font-size-base;
						font-weight: $font-weight-bold;
						line-height: inherit;
						color:  var(--w3-primary-color);;

						content: "\f00c";
					}
				}
			}
		}
	}
}
