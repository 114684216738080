// btn
.btn {
	box-shadow: $shadow;
	font-weight: 400;
	text-transform: uppercase;
	border-radius: 3px;
	font-family: $font-family-secondary;
	font-size: 14px;
	padding: 7px 20px;
	color: $white;
	&:hover {
		color: $white;
	}
	// btn-primary
	&.btn-primary {
		background-color: var(--w3-primary-color);
		border-color: var(--w3-primary-color);
		&:hover {
			background-color: darken(var(--w3-primary-color), 20%);
			border-color: darken(var(--w3-primary-color), 20%);
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		color: var(--w3-primary-color);
		border-color: var(--w3-primary-color);
		&:hover {
			background-color: var(--w3-primary-color);
			border-color: var(--w3-primary-color);
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		background-color: var(--w3-secondary-color);
		border-color: var(--w3-secondary-color);
		&:hover {
			background-color: darken(var(--w3-secondary-color), 20%);
			border-color: darken(var(--w3-secondary-color), 20%);
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: var(--w3-secondary-color);
		border-color: var(--w3-secondary-color);
		&:hover {
			background-color: var(--w3-secondary-color);
			border-color: var(--w3-secondary-color);
			color: $white;
		}
	}

	&.btn-tertiary {
		background-color: var(--w3-tertiary-color);
		border-color: var(--w3-tertiary-color);
		color: $black;
		&:hover {
			background-color: darken(var(--w3-tertiary-color), 20%);
			border-color: darken(var(--w3-tertiary-color), 20%);
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// zoek en book button
.book-button {
	list-style: none;
	margin: 0;
	padding: 22px 10px;
	width: 142px;
	background-color: var(--w3-secondary-color);
	height: max-content;
	-webkit-mask-image: url("/images/blobje.svg");
	mask-image: url("/images/blobje.svg");
	mask-repeat: no-repeat;
	mask-size: 100%;
	position: absolute;
	right: 8.5rem;
	bottom: -50px;
	transition: .3s;

	&:hover {
		background-color: darken(var(--w3-secondary-color), 10%);
		bottom: -40px;
	}

	a {
		text-decoration: none;
		color: $white;
		text-transform: uppercase;
		font-size: 20px;
		text-align: center;
		transform: rotate(9deg);
		display: inline-block;
		font-family: $font-family-secondary;
		font-weight: 400;
	}

	@include media-breakpoint-down(lg) {
		right: 1rem;
		bottom: -30px;
		order: 1;
	}

	@include media-breakpoint-down(sm) {
		bottom: -50px;
	}
}
