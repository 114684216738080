.link-box {
    background-color: $white;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba($black, .3);
    font-size: 36px;
    @include media-breakpoint-down(md) {
        font-size: 28px;
    }

    &.top-box {
        margin-bottom: 50px;
    }

    &.bottom-box {
        margin-top: 50px;
    }

    &.orange-box {
        background-color: $orange;
    }

    &.green-box {
        background-color: $green;
    }

    a {
        padding: 50px 36px 30px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-flow: column;
        color: $white;
        text-decoration: none;
        font-weight: 400;
        @include media-breakpoint-down(md) {
            padding: 35px 30px 20px;
        }
        p {
            margin: 0;
            text-align: right;
            width: 100%;
            &:first-child {
                font-family: $font-family-tertiary;
            }
    
            &:last-child {
                font-family: $font-family-secondary;
                max-width: 335px;
            }
        }
    }

}