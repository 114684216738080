&.timeslot_overview {
	.timeslot-overview-row {
		@include make-row();
		.timeslot-datepicker-holder,
		.timeslot-item-holder {
			margin-bottom: 30px;
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.timeslot-item {
				.timeslot-item-title {
					line-height: normal;
				}

                .timeslot-item-days {
                    .timeslot-item-day {
                        .timeslot-item-day-title-holder,
                        .timeslot-item-day-times-holder {
                            @include make-col(6);
                        }

                    }
                }
			}
		}
	}
}
