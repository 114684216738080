// footer
.footer {
    margin-top: 3rem;
    .footer-outro {
        position: relative;
        bottom: -1px;

        .container-holder {
            justify-content: center;
        }
        .footer-img {
            position: relative;
            @include media-breakpoint-down(sm) {
                margin: 0 1rem;
            }

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: var(--w3-primary-color);
                -webkit-mask-image: url("/images/footer-img-wave.svg");
                mask-image: url("/images/footer-img-wave.svg");
                mask-repeat: no-repeat;
                mask-size: 100%;
            }
        }

        .book-button {
            left: 50%;
            z-index: 3;
            transform: translateX(-6px);
        }
    }
    
    .footer-sitelinks {
        background-color: var(--w3-primary-color);
        padding: 5.6rem 0 1.6rem;
        color: $white;

        .container-holder {
            @include media-breakpoint-down(sm) {
                padding: 0 1rem;
            }
        }

        h1, h2, h3, h4, h5, h6, a { color: $white; }

        .tel-btn {
            background-color: $secondary;
            color: $white;
            
            &:hover {
                background-color: lighten($secondary, 20%);
            }
        }

        .social-media {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 10px;
                a {
                    text-decoration: none;
                    i {
                        width: 16px;
                        text-align: center;
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        padding: 1.5rem 0;
        .container-holder {
            justify-content: center;
            @include media-breakpoint-down(sm) {
                padding: 0 1rem;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-flow: row nowrap;
            column-gap: 2rem;
            align-items: center;

            a {
                text-decoration: none;
            }
        }
    }
}
