// header
.header {
	z-index: 20;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: unset;
	transition: 0.5s;

	@include media-breakpoint-down(lg) {
		background-color: $white;
		box-shadow: $shadow;
	}

	&.menu-toggled {
		background-color: $white;
		box-shadow: $shadow;
	}

	&.sticky {
		position: fixed;
		box-shadow: $shadow;
		background-color: $white;

		.logo {
			max-width: 220px;
			margin-left: auto;
			margin-right: auto;
		}

		.header-main {
			min-height: 69px;
		}

		.book-button {
			top: 1rem;
			&:hover {
				top: 0.5rem;
			}
		}
	}

	// .container,
	.container-fluid {
		.container-holder {
			margin-left: 0;
			margin-right: 0;
			@include media-breakpoint-up(sm) {
				padding: 0 1rem;
			}
		}
	}

	// header top
	.header-top {
		background-color: var(--w3-primary-color);
		padding-top: 10px;
		padding-bottom: 10px;
		.container-holder {
			display: flex;
			align-items: center;
		}
	}

	// menu-toggle
	.menu_toggle {
		margin-right: auto;
		background-color: $white;
		border-radius: 20px;
		display: flex;
		align-items: center;
		font-size: 1rem;
		padding: 10px 20px;
		transition: all 0.3s;
		width: 100%;
		max-width: 115px;
		box-shadow: 0 0 0 $white;

		&.menu-toggled {
			border-radius: 18px 18px 0 0;
			box-shadow: 0 1rem 0 $white;
			max-width: 325px;

			@include media-breakpoint-down(sm) {
				max-width: unset;
				width: auto;
			}
		}

		.navbar-toggler-icon {
			width: 15px;
			height: auto;
			margin-right: 10px;
			line-height: 10px;
		}
	}

	// header main
	.header-main {
		position: relative;
		padding: 10px 0;

		.container-three-columns {
			> .container-holder {
				> .column {
					margin-bottom: 0;
				}
			}
		}
	}

	// logo
	.logo {
		margin: auto;
		max-width: 400px;
		width: 100%;
		text-align: center;
		order: 1;
		transition: max-width 0.3s;

		@include media-breakpoint-down(lg) {
			max-width: 350px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 210px;
			margin: 0 auto 0 0;
		}

		a {
			@extend .d-inline-block;
		}

		img {
			width: 400px;
			max-width: 100%;
		}
	}

	// menu
	.menu {
		&#top_menu_menu {
			@include media-breakpoint-down(lg) {
				display: block;
				order: 3;
				flex-basis: unset !important;
				margin-left: 0.5rem;
				flex-grow: unset;
				li.nav-item {
					display: none;
					&:nth-child(1) {
						display: block;
						.nav-link {
							color: $orange;
							background: $white;
							border-radius: 35px;
							padding: 10px 25px;
						}
					}
				}
				ul.dropdown-menu.show {
					position: fixed !important;
					left: 0 !important;
					top: 6vh !important;
					width: 100% !important;
					transform: none !important;
					background: $primary;
					padding: 1.5rem 0;
					border: 0;
					li {
						display: block;
						.nav-link {
							color: $black;
							background: transparent;
						}
						&.active .nav-link {
							color: $orange;
						}
					}
				}
			}

			@include media-breakpoint-up(lg) {
				display: block;
				flex: 0 0 auto;
				margin: 0 1rem;

				.navbar-nav {
					flex-direction: row;
					column-gap: 1rem;

					.nav-item {
						.nav-link {
							color: $white;
						}
						&:hover,
						&.active {
							> .nav-link {
								color: $primary;
							}
						}

						// submenu
						.dropdown-menu {
							left: 0 !important;
							transform: translateX(0) !important;
							border-radius: 1rem;
							box-shadow: 0 10px 20px rgba($black, 0.3);
							border: none;
							.nav-item {
								.nav-link {
									padding: 0.5rem 1rem;
									color: $black;
								}
								&:hover,
								&.active {
									> .nav-link {
										color: var(--w3-primary-color);
									}
								}
							}

							&::after,
							&::before {
								display: none;
							}
						}
					}
				}
			}
		}

		&#default_menu {
			padding: 0 20px;
			> .navbar-nav {
				> .nav-item {
					.menu-icon {
						margin-right: 10px;
						width: 20px;
						display: inline-block;
					}

					&.active {
						> .nav-link {
							color: var(--w3-primary-color);
						}
					}

					// sub-menu
					.dropdown-menu {
						position: relative !important;
						background-color: unset;
						border: none;
						box-shadow: none;
						top: unset !important;
						left: unset !important;
						transform: none !important;
						padding: 0 0 0 30px;

						.nav-item {
							.nav-link {
								font-weight: 400;
								padding: 0 0 10px;
							}
							&.active {
								> .nav-link {
									color: var(--w3-primary-color);
								}
							}
						}

						&::before {
							display: none;
						}
					}
				}
			}
			@include media-breakpoint-down(lg) {
				order: 0;
				> .navbar-nav {
					margin-bottom: 1.5rem;
				}
			}

			@include media-breakpoint-up(lg) {
			}
		}
	}

	// site-switcher
	.site_switcher {
		.navbar-nav {
			display: flex;
			flex-flow: row nowrap;
			column-gap: 5px;

			.nav-item {
				img {
					width: 20px;
					height: 20px;
					object-fit: cover;
					border-radius: 50%;
					border: 2px solid white;
				}
			}
		}
	}

	// book-button
	.book-button {
		@include media-breakpoint-down(sm) {
			bottom: -24px;
			padding: 18px 10px;
			width: 114px;

			a {
				font-size: 1rem;
			}
		}
	}

	// header top (Topdeals) button
	.page_block.link {
		background-color: $white;
		color: var(--w3-secondary-color);
		border-radius: 50px;
		padding: 10px 20px;
		text-decoration: none;
		margin-left: 1rem;
		line-height: normal;

		&:hover {
			background-color: var(--w3-secondary-color);
			color: $white;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
