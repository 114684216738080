.gallery-slider {
    @include media-breakpoint-down(sm) {
        padding: 0 2rem;
    }
    .owl-stage-outer {
        overflow: visible;
        .owl-item {
            &:not(:first-child) {
                @include media-breakpoint-up(sm) {
                    margin-left: -2.8rem;
                }
            }
            
            // rotation
            &:nth-child(2n +1) {
                transform: rotate(-5deg);
            }
            &:nth-child(2n +2) {
                transform: translateY(2rem) rotate(5deg);
            }
        }
    }
}