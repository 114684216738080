// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 350px;
	min-height: 300px;
	max-height: 540px;

	@include media-breakpoint-down(sm) {
		height: 400px;
		min-height: 360px;
	}

	.container-holder {
		margin: 0;
	}

    picture {
        flex: 1 1 auto;
        height: 100%;

		img {
			height: 100%;
		}
    }
	
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 600px;
	min-height: 540px;
	max-height: 1080px;


	@include media-breakpoint-down(lg) {
		height: 280px;
		min-height: 100px;
	}


	@include media-breakpoint-down(sm) {
		height: 400px;
		min-height: 360px;
	}

	.container-holder {
		margin: 0;
	}

    picture {
        flex: 1 1 auto;
        height: 100%;
    }
}
