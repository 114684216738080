.page_block.search_book {
	flex: 1;
}

&.accommodation_search_book {
	.main {
		> .content {
			margin-top: 2rem;
		}
	}
	.search_book {
		flex: 0 0 100%;
	}
}

.w3-vue-booking-wrapper {
	.list-item-availability {
		.price-old {
			font-size: 0.95rem;
			font-weight: 600;
			color: $orange;
		}
	}
	.widget-element {
		.filters {
			margin-top: 1rem;
			padding: 6px 1rem 1rem;
			border-radius: 20px;
			background: $primary;
			box-shadow: 0 0 20px rgba($black, 0.15);
			.filter-element {
				// elements width
				@include media-breakpoint-up(xl) {
					flex: 0 0 25%;
					max-width: 25%;
				}
			}
		}
	}

	&.w3-searchbook-widget {
		.widget-element {
			.tags {
				display: none;
			}
		}

		.results {
			.no-results {
				color: var(--w3-booking-widget-primary-color);
			}
		}

		.sorting {
			.w3-radio {
				.input-holder {
					.input {
						background-color: $white;
						border: 1px solid $white;
						border-radius: 4px;
					}

					.current-item {
						color: var(--w3-booking-widget-primary-color);
					}
				}

				.w3-popover-body {
					background-color: $white;
					border-radius: 4px;

					.radio-row {
						&.active,
						&.focus {
							.label {
								background-color: var(
									--w3-booking-widget-primary-color
								);
								color: $white;
							}
						}

						.label {
							color: var(--w3-booking-widget-primary-color);
							&:hover {
								background-color: var(
									--w3-booking-widget-primary-color
								);
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}
