// footer
&.main {
    .footer {
        .footer-sitelinks {
            color: $black !important;
    
            h1, h2, h3, h4, h5, h6, a:not(.tel-btn) { color: $black !important; }
    
            .custom-list {
                li {
                    &::before {
                        color: $black;
                    }
                }
            }
        }
    }
}
