.page_block {
	&.contentblock.grid {
		width: 100%;
		display: flex;
		row-gap: 2rem;
		flex-wrap: wrap;
		padding: 0 1rem;
		@include media-breakpoint-up(sm) {
			padding: 0;
			// flex-wrap: nowrap;
		}

		// if there is more one item make two columns
		&:has(.item + .item) {
			.item {
				padding: 0 1.5rem;
				flex: 0 0 100%;
				max-width: 100%;
				@include media-breakpoint-up(sm) {
					flex: 0 0 50%;
					max-width: 50%;
				}

				&:first-child {
					@include media-breakpoint-down(sm) {
						margin-bottom: 3rem;
					}
				}
			}
		}

		// if has text-end class
		// make all texts align right
		&.text-end {
			.item {
				.card {
					&.card-overlay {
						.card-img-overlay {
							align-items: flex-end;

							.card-caption {
								text-align: right;

								.card-title {
									text-align: right;
									margin-left: auto;
									max-width: 260px;
								}
							}
						}
					}
				}
			}
		}

		// make btn orange (default = green)

		&.orange-link {
			.item {
				.card.card-overlay {
					.card-img-overlay {
						.card-buttons {
							.card-btn {
								background: $orange;
								border-color: $orange;

								&:hover {
									background: darken($orange, 20%);
									border-color: darken($orange, 20%);
								}
							}
						}
					}
				}
			}
		}

		// label-bg (default = green)
		&.orange-label {
			.card {
				&.card-overlay {
					.card-img-overlay {
						.card-caption {
							.card-description {
								background-color: $orange !important;
							}
						}
					}
				}
			}
		}

		&.yellow-label {
			.card {
				&.card-overlay {
					.card-img-overlay {
						.card-caption {
							.card-description {
								background-color: $yellow !important;
							}
						}
					}
				}
			}
		}

		// add margin top
		&.extra-top-space {
			margin-top: 1rem;
		}

		.item {
			flex: 0 0 100%;
			max-width: 100%;
			aspect-ratio: 1;
			.card {
				border-radius: 20px;
				box-shadow: 0 0 20px rgba($black, 0.3);
				&.card-overlay {
					border: unset;
					background-color: unset;
					background-clip: unset;
					position: relative;
					overflow: visible;
					.card-image {
						height: 100%;
						border-radius: 20px;

						img {
							height: 100%;
						}
					}
					.card-img-overlay {
						background: transparent;
					}
					.card-img-overlay:has(.card-caption) {
						background: linear-gradient(
							to bottom,
							rgba($black, 0) 30%,
							rgba($black, 0.3) 80%
						);

						padding: 2.75rem;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						overflow: visible;
						border-radius: 20px;

						@include media-breakpoint-down(md) {
							padding: 1rem 1.3rem;
						}

						.card-caption {
							flex: 0 0 auto;

							.card-subtitle {
								font-family: $font-family-tertiary;
								font-size: 2.25rem;
								@include media-breakpoint-down(md) {
									font-size: 1.875rem;
									margin-bottom: 0;
								}
							}
							.card-title {
								font-size: 2rem;
								width: 100%;
								max-width: 240px;
								word-wrap: normal;
								@include media-breakpoint-down(md) {
									font-size: 1.875rem;
									margin-bottom: 0;
								}
							}

							.card-description {
								background-color: $green;
								position: absolute;
								top: -50px;
								left: 30px;
								padding: 1.5rem 1rem;
								width: 152px;
								height: max-content;
								-webkit-mask-image: url("/images/blobje.svg");
								mask-image: url("/images/blobje.svg");
								mask-repeat: no-repeat;
								transform: scaleX(-1);
								text-shadow: 3px 3px 12px rgba($black, 0.3);

								* {
									display: none !important;
								}

								p {
									display: inline-block !important;
									text-decoration: none;
									color: $white;
									text-transform: uppercase;
									font-size: 22px;
									text-align: center;
									transform: scaleX(-1) rotate(-9deg);
									display: inline-block;
									font-family: $font-family-secondary;
									font-weight: 400;
								}
							}
						}

						.card-buttons {
							.card-btn {
								background: $green;
								border-color: $green;
								font-family: $font-family-secondary;

								&:hover {
									color: $white;
									background: darken($green, 20%);
									border-color: darken($green, 20%);
								}
							}
						}
					}
				}
			}
			&:nth-child(2) {
				.card {
					&.card-overlay {
						.card-img-overlay {
							.card-caption {
								.card-description {
									background-color: $yellow;
								}
							}
						}
					}
				}
			}
		}
	}
}

.block-section {
	.page_block {
		&.contentblock.grid {
			padding: 0;
		}
	}
}
