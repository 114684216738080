&.bundle-overview {
    .vacancy_overview {
        @include make-row();

        .item {
            margin-bottom: 1rem;
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}
        }
    }
}