// body
& {
	overflow-x: clip;
	background-image: url("/images/body-background.png");
	background-color: var(--w3-tertiary-color);
	background-position: top center;
}

// container
.container {
	@include media-breakpoint-down(sm) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// container-two-columns
.container-two-columns {
	.container-holder {

		> .column.one {
			padding-right: 1.5rem !important;
		}

		> .column.two {
			padding-left: 1.5rem !important;
		}
	}
}

.main {
	z-index: 9;
	position: relative;
}

// box style
.box-style {
	background: $white;
	padding: 2rem;
	box-shadow: 0 0 20px rgba($black, .3);
	border-radius: 20px;
	@include media-breakpoint-down(sm) {
		padding: 2rem 1rem;
	}
}

.page_block.form,
.content-box {
	@extend .box-style;
	margin-top: 2rem;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// links font family
a,
.btn,
.card-btn,
.navbar-toggler-label {
	font-family: $font-family-primary;
	font-weight: 700;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
// .default-card {
// }

// container-two-columns
.container-two-columns,
.container-three-columns,
.container-four-columns,
.container-six-columns {
	.container-holder {
		margin-bottom: 0;
	}
}
