.w3-vue-booking-wrapper {
	&.w3-minisearchbook-widget {

		// elements width
		.widget-element {
			.filters {
				.filter-element {
					@include media-breakpoint-up(xl) {
						flex: 0 1 290px;
						max-width: 290px;
					}

					&.filter-context-button {
						flex: 0 0 auto;
						@include media-breakpoint-only(md) {
							flex: 1 1 auto;
						}
						@include media-breakpoint-down(sm) {
							margin-top: 10px;
						}
					}
				}
			}

			.widget-btn {
				background-color: var(--w3-booking-widget-secondary-color);
				border: 1px solid var(--w3-booking-widget-secondary-color);
				border-radius: 30px;
				font-family: $font-family-secondary;
				text-transform: uppercase;
				padding: 1rem 22px;
				font-size: 14px;
				height: 100%;
				box-shadow: none;
				font-weight: 400;
				max-height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;

				@include media-breakpoint-down(sm) {
					width: 100%;
				}

				&:focus,
				&:hover {
					background-color: $white;
					border: 1px solid $white;
					color: var(--w3-booking-widget-secondary-color);
				}
			}
		}

		
	}
}
