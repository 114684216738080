.lastminute_widget {
    width: 100%;
}

.w3-vue-booking-wrapper {
	&.w3-lastminute-widget {
        .results {
            .loading:not(.matrix-toggle) {
                .loading-title,
                .loading-message {
                    color: var(--w3-booking-widget-secondary-color);
                }
    
                &::before {
                    border-color: var(--w3-booking-widget-secondary-color) var(--w3-booking-widget-secondary-color) var(--w3-booking-widget-secondary-color) transparent;
                }
            }

            .no-results {
                color: var(--w3-booking-widget-secondary-color);
            }
        }
    }
}