// theme setup
@import '~framework/assets/scss/V2.1/0_config/_mixins';
@import './0_config/_variables';

// theme within body class
body {
	// app styling
	@import "./2_layout/footer";
	@import "./2_layout/header";
}
