// tommy
.tommy {
	margin: 3rem 0;
	.container {
		@extend .position-relative;
		.tommy-holder {
			@extend .box-style;
			z-index: 100;
			position: relative;
			margin-top: -25px;

			@include media-breakpoint-up(sm) {
				padding: 30px;
			}
		}
	}

	.review-badge {
		position: relative;
		z-index: 100;

		@extend .d-none;
		visibility: hidden;

		//@extend .d-flex;
		@extend .flex-column;
		@extend .align-items-center;
		@extend .justify-content-center;

		width: 150px;
		height: 150px;
		margin: -100px auto 0 auto;
		padding: 0 40px;

		background: url("~project/public/images/badge.svg");
		background-size: cover;

		@extend .text-center;
		font-size: 22px;
		font-weight: 700;
		line-height: 0.8;
		color: $white;

		@include media-breakpoint-up(lg) {
			position: absolute;
			top: -100px;
			right: 0;

			width: 215px;
			height: 215px;
			margin: 0;
			padding: 0 35px;

			font-size: $font-size-base * 1.875;
		}

		.review-rating {
			@extend .d-block;

			font-size: $font-size-base * 2;

			@include media-breakpoint-up(lg) {
				font-size: $font-size-base * 3.75;
			}
		}
	}

	.tommy-title {
		margin-bottom: 1rem;
		color: $body-color;
		text-align: center;
	}

	.view {
		margin: 35px 0 0 0;
	}

	.matrix-price-description {
		margin-top: 35px;
	}
}

// Colors
.w3-vue-booking-wrapper .matrix-cel {
	background: #f8f9fa;
}

.w3-vue-booking-wrapper .matrix-header {
	background: #fff;
}

.w3-vue-booking-wrapper .matrix-header .matrix-cel {
	background: #f8f9fa;
}

.w3-vue-booking-wrapper .matrix-controls {
	background: #f8f9fa;
}

.w3-vue-booking-wrapper .matrix-controls .btn-next,
.w3-vue-booking-wrapper .matrix-controls .btn-prev {
	color: var(--w3-booking-widget-primary-color);
}

.w3-vue-booking-wrapper .matrix-controls .btn-next:active,
.w3-vue-booking-wrapper .matrix-controls .btn-next:focus,
.w3-vue-booking-wrapper .matrix-controls .btn-next:hover,
.w3-vue-booking-wrapper .matrix-controls .btn-prev:active,
.w3-vue-booking-wrapper .matrix-controls .btn-prev:focus,
.w3-vue-booking-wrapper .matrix-controls .btn-prev:hover {
	color: var(--w3-booking-widget-secondary-color);
}

.w3-vue-booking-wrapper .matrix .startdate {
	background: #f8f9fa;
}

.w3-vue-booking-wrapper .matrix .startdate .date {
	color: #000;
}

.w3-vue-booking-wrapper .matrix .startdate .day {
	color: #000;
}

.w3-vue-booking-wrapper .matrix-body {
	background: #fff;
}

.w3-vue-booking-wrapper .matrix-body .matrix-cel {
	background: #f8f9fa;
}

.w3-vue-booking-wrapper .matrix .duration {
	background: var(--w3-booking-widget-secondary-color);
	color: #fff;
}
.w3-vue-booking-wrapper .matrix-row.selected .matrix-cel .duration {
	background: lighten(var(--w3-booking-widget-secondary-color), 20%);
}

.w3-vue-booking-wrapper .matrix .available {
	background: var(--w3-booking-widget-primary-color);
}

.w3-vue-booking-wrapper .matrix-body .matrix-cel.selected .available {
	background: lighten(var(--w3-booking-widget-primary-color), 20%);
}

&.w3-channel-hengelhoef {
	.w3-vue-booking-wrapper .matrix .available .prices .price {
		color: #fff !important;
	}

	.w3-vue-booking-wrapper .matrix .available .prices .price-old {
		color: #fff !important;
	}

	.w3-vue-booking-wrapper .matrix .available .prices .discount-price {
		color: #fff !important;
	}

	.w3-vue-booking-wrapper .matrix .available .price-info {
		color: #fff !important;
	}
}
.w3-vue-booking-wrapper .matrix .available .prices .price {
	color: #000;
}

.w3-vue-booking-wrapper .matrix .available .prices .price-old {
	color: #000;
}

.w3-vue-booking-wrapper .matrix .available .prices .discount-price {
	color: #000;
}

.w3-vue-booking-wrapper .matrix .available .price-info {
	color: #000;
}

.w3-vue-booking-wrapper .matrix .available:focus,
.w3-vue-booking-wrapper .matrix .available:hover {
	background: #fff;
	.prices {
		.price,
		.price-old,
		.discount-price {
			color: #000 !important;
		}
	}
	.price-info {
		color: #000 !important;
	}
}

.w3-vue-booking-wrapper .matrix .available:focus .prices .discount-price,
.w3-vue-booking-wrapper .matrix .available:focus .prices .price,
.w3-vue-booking-wrapper .matrix .available:focus .prices .price-info,
.w3-vue-booking-wrapper .matrix .available:focus .prices .price-old,
.w3-vue-booking-wrapper .matrix .available:hover .prices .discount-price,
.w3-vue-booking-wrapper .matrix .available:hover .prices .price,
.w3-vue-booking-wrapper .matrix .available:hover .prices .price-info,
.w3-vue-booking-wrapper .matrix .available:hover .prices .price-old {
	color: #000;
}

.w3-vue-booking-wrapper .matrix-holder .matrix-price-description {
	padding: 10px;
	font-size: 0.75rem;
}

.w3-vue-booking-wrapper .matrix-price-description .description,
.w3-vue-booking-wrapper .matrix-price-description .icon {
	color: #000;
}

.w3-vue-booking-wrapper .matrix-show-more-btn {
	background: var(--w3-booking-widget-primary-color);
	border: 1px solid var(--w3-booking-widget-primary-color);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	color: #fff;
}

.w3-vue-booking-wrapper .matrix-show-more-btn:hover {
	background: #fff;
	border: 1px solid #fff;
	color: var(--w3-booking-widget-primary-color);
}
