.w3-vue-booking-wrapper {
	font-family: $font-family-primary;

    .filters {

        .input-holder {
            &::before {
                content: "";
                width: 42px;
                height: 46px;
                background-color: lighten($yellow, 85%);
                display: block;
                position: absolute;
                top: 1px;
                left: 1px;
                text-align: center;
                line-height: 48px;
                z-index: 1;
                border-radius: 4px 0 0 4px;
                font-family: "Font Awesome 5 Pro";
            }
        }
    
        // inputs icon
        .w3-travelgroup {
            .input-holder {
                &::before {
                    content: "\f0c0";
                }
            }
        }
    
        .w3-radio {
            .input-holder {
                &::before {
                    content: "\f8f7";
                }
            }
        }
    
        .w3-daterangepicker {
            .input-holder {
                &::before {
                    content: "\f073";
                }
            }
        }
    
        .w3-checkbox {
            .input-holder {
                &::before {
                    content: "\f5ca";
                }
            }
        }
    }

	// inputs & labels
	.w3-travelgroup,
	.w3-radio,
	.w3-checkbox {
		.input-holder {
			.label {
				display: none !important;
			}
			.input {
				height: 48px !important;
				line-height: 2 !important;
				box-shadow: none !important;
				font-weight: bold !important;
				border: 1px solid $gray !important;
				padding: 6px 40px 6px 54px !important;
			}
		}
	}
	.w3-autocomplete,
	.w3-daterangepicker {
		.label {
			display: none !important;
		}
		input {
			height: 48px !important;
			line-height: 2 !important;
			box-shadow: none !important;
			font-weight: bold !important;
			border: 1px solid $gray !important;
			padding: 6px 40px 6px 54px !important;
			&:focus {
				border: 1px solid $gray !important;
			}
		}
	}

	// elements width
	.widget-element {
		.filters {
			justify-content: center !important;
			@include media-breakpoint-up(xl) {
				flex-wrap: nowrap !important;
			}
		}
	}

	.icon-input {
		height: 44px !important;
		line-height: 46px !important;
		color: $black !important;
		bottom: 2px !important;
		border-radius: 0 4px 4px 0 !important;

		&:hover {
			color: $black !important;
		}
	}

	.badge {
		vertical-align: middle !important;
		padding-top: 5px !important;
	}

	// colors
	.w3-travelgroup {
		.popover-travelgroup {
			.travelgroup-row {
				.handle {
					.travelgroup-btn {
						background-color: var(--w3-booking-widget-primary-color);
						border: 1px solid var(--w3-booking-widget-primary-color);

						&:hover {
							background-color: var(--w3-booking-widget-secondary-color);
							border: 1px solid var(--w3-booking-widget-secondary-color);
						}
					}
				}
			}
		}
	}
	.w3-daterangepicker {
		.vc-popover-content-wrapper {
			.vc-popover-content {
				.vc-container {
					.vc-weeks {
						.vc-weekday {
							color: var(--w3-booking-widget-primary-color);
						}

						.vc-day {
							.vc-highlights {
								.vc-highlight {
									border-color: var(--w3-booking-widget-primary-color);
									color: $white;
									&.vc-highlight-base-middle,
									&.vc-highlight-base-end,
									&.vc-highlight-base-start {
										background: rgba(var(--w3-booking-widget-primary-color), 0.6);
									}

									&.vc-rounded-full {
										background: var(--w3-booking-widget-primary-color);
									}
								}
							}
						}
					}

					.vc-header {
						.vc-title:hover {
							color: var(--w3-booking-widget-primary-color);
						}
					}
				}

				.vc-arrows-container {
					> div:hover {
						.vc-svg-icon {
							color: var(--w3-booking-widget-primary-color);
						}
					}
				}

				.vc-nav-container {
					.vc-grid-container {
						.vc-grid-cell {
							span.vc-bg-blue-100,
							span:hover {
								background: var(--w3-booking-widget-primary-color);
								border: 1px solid var(--w3-booking-widget-primary-color);
							}

							span.vc-border-blue-100 {
								border-color: var(--w3-booking-widget-primary-color);
							}
						}
					}
				}
			}
		}
	}

	.w3-radio {
		.w3-popover-body {
			.radio-row {
				&.active,
				&.focus {
					.label {
						background: var(--w3-booking-widget-primary-color);
					}
				}
				.label:hover {
					background: var(--w3-booking-widget-primary-color);
				}
			}
		}
	}

	.w3-checkbox {
		.w3-popover-body {
			.checkbox-row {
				&.active,
				&.focus {
					.checkbox-item {
						.state {
							label {
								color: var(--w3-booking-widget-primary-color);
							}
						}
					}
				}
				.checkbox-item {
					.state {
						label {
							&:hover {
								color: var(--w3-booking-widget-primary-color);
							}
						}
					}
				}
			}

			.checkbox-actions {
				.btn {
					background-color: var(--w3-booking-widget-primary-color);
					border-color: var(--w3-booking-widget-primary-color);

					&:hover {
						background-color: $white !important;
						border-color: $white !important;
						color: var(--w3-booking-widget-primary-color) !important;
					}
				}
			}
		}
	}

	// loading
	&.w3-searchbook-widget {
		.results {
			.loading:not(.matrix-toggle) {
                .loading-title,
                .loading-message {
                    color: var(--w3-booking-widget-secondary-color);
                }
    
                &::before {
                    border-color: var(--w3-booking-widget-secondary-color) var(--w3-booking-widget-secondary-color) var(--w3-booking-widget-secondary-color) transparent;
                }
            }

            .no-results {
                color: var(--w3-booking-widget-secondary-color);
            }
		}
	}
}
