.slider-block {

    // Home Page
    // blauwemeer colors
    &.blauwemeer {
        .btn {
            background-color: $blue;
            border: 1px solid $blue;
            &:hover {
                background-color: darken($blue, 20%);
                border: 1px solid darken($blue, 20%);
            }
        }

        .owl-carousel.slider {
            .owl-dots {
                .owl-dot {
                    &.active {
                        span {
                            background-color: $blue;
                        }
                    }
                    &:hover {
                        span {
                            background-color: $blue;
                        }
                    }
                    span {
                        border-color: $blue;
                        &:hover {
                            background-color: $blue;
                        }
                    }
                }
            }
        }
    }

    // Home Page
    // hengelhoef colors
    &.hengelhoef {
        .btn {
            background-color: $green-dark;
            border: 1px solid $green-dark;
            &:hover {
                background-color: darken($green-dark, 20%);
                border: 1px solid darken($green-dark, 20%);
            }
        }
        .owl-carousel.slider {
            .owl-dots {
                .owl-dot {
                    &.active {
                        span {
                            background-color: $green-dark;
                        }
                    }
                    &:hover {
                        span {
                            background-color: $green-dark;
                        }
                    }
                    span {
                        border-color: $green-dark;
                        &:hover {
                            background-color: $green-dark;
                        }
                    }
                }
            }
        }
    }

    .container-holder {
        @extend .box-style;
        position: relative;
        align-items: center;
        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 50px 1rem 35px;
        }

        .column {
            margin-bottom: 0;

            &:not(:has(.slider)) {
                z-index: 999;
            }
        }

        .resource_image {
            margin-bottom: 1.5rem;
            @include media-breakpoint-down(sm) {
                margin-bottom: 21rem;
            }
        }

        .subtitle {
            font-family: $font-family-tertiary;
        }

        ul {
            padding: 0;
            list-style: none;
        }

        // mobile slider 
        @include media-breakpoint-down(sm) {
            &:has(.resource_image) {
                &:has(.right-slider) {
                    .column.two {
                        position: absolute;
                        top: 9rem;
                        left: 0;
                    }
                }
    
                &:has(.left-slider) {
                    .column.one {
                        position: absolute;
                        top: 9rem;
                        left: 0;
                        z-index: 1000;
                    }
                }
            }

            &:not(:has(.resource_image)) {
                .right-slider {
                    margin-top: 3rem;
                }

                .left-slider {
                    margin-bottom: 2rem;
                }

                .owl-stage-outer {
                    @include media-breakpoint-down(sm) {
                        transform: scale(1.3) !important;
                    }
                }

                .owl-dots {
                    @include media-breakpoint-down(sm) {
                        margin-top: 50px !important;
                        margin-bottom: 0;
                    }
                }
    
            }
        }

        .owl-carousel.slider {

            .owl-dots {
                @include media-breakpoint-down(md) {
                    padding: 0 15px;
                    margin-top: 60px;
                    margin-bottom: -2rem;
                }
                @include media-breakpoint-down(sm) {
                    margin-top: 30px;
                    margin-bottom: 0;
                }
            }

            .owl-stage-outer {
                @include media-breakpoint-down(md) {
                    transform: scale(1.3);
                }

                @include media-breakpoint-down(sm) {
                    transform: scale(1.15);
                }
            }

            &.right-slider {
                .owl-stage-outer {
                    @include media-breakpoint-only(md) {
                        right: -20px;
                    }

                    @include media-breakpoint-up(lg) {
                        right: -60px;
                    }
                }
                .owl-item {
                    transform: rotate(-15deg);
                    &.active {
                        transform: rotate(5deg);
                    }
                }
                .owl-dots {
                    @include media-breakpoint-down(sm) {
                        justify-content: flex-start;
                    }
                }
            }
            &.left-slider {
                .owl-stage-outer {
                    @include media-breakpoint-only(md) {
                        left: -20px;
                    }
                    @include media-breakpoint-up(lg) {
                        left: -60px;
                    }
                }
                .owl-item {
                    transform: rotate(15deg);
                    &.active {
                        transform: rotate(-5deg);
                    }

                }

                .owl-dots {
                    @include media-breakpoint-down(sm) {
                        justify-content: flex-end;
                    }
                }
            }
            .owl-stage-outer {
                overflow: visible;
                .owl-item {
                    opacity: 0;
                    visibility: hidden;
                    transition: 1s;
                    
                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

// Other campings
&.default {
    .slider-block {
        .btn {
            background-color: var(--w3-primary-color);
            border: 1px solid var(--w3-primary-color);
            &:hover {
                background-color: darken(var(--w3-primary-color), 20%);
                border: 1px solid darken(var(--w3-primary-color), 20%);
            }
        }
        
        .owl-carousel.slider {
            .owl-dots {
                .owl-dot {
                    &.active {
                        span {
                            background-color: var(--w3-primary-color);
                        }
                    }
                    &:hover {
                        span {
                            background-color: var(--w3-primary-color);
                        }
                    }
                    span {
                        border-color: var(--w3-primary-color);
                        &:hover {
                            background-color: var(--w3-primary-color);
                        }
                    }
                }
            }
        }
    }
}