&.bundle-detail {
	.vacancy_detail,
    .service_detail {
        margin-top: 3rem;
        @include make-row();

        .info,
		.info-extra {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}
    }
}