&.main {

	// header
	.header {
		// menu
		.menu {
			&#top_menu_menu {
				@include media-breakpoint-up(lg) {
					.navbar-nav {
						.nav-item {
							.nav-link {
								color: $black;
							}
							&:hover,
							&.active {
								> .nav-link {
									color: var(--w3-secondary-color);
								}
							}
							// submenu
							.dropdown-menu {
								.nav-item {
									.nav-link {
										color: $black;
									}
									&:hover,
									&.active {
										> .nav-link {
											color: var(--w3-primary-color);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
