.card {
    border: none;
    border-radius: 0;
    &:not(.card-overlay) {
        border-radius: 20px;
        box-shadow: 0 0 20px rgba($black, .3);
    }

    .card-body {
        padding: 25px 20px;

        .card-subtitle {
            font-family: $font-family-tertiary;
            margin-bottom: 0;
        }

        .card-description {
            font-weight: 400;
        }

        .card-btn {
            @extend .btn;
            @extend .btn-primary;
        }
    }
}