&.accommodation_book {
    .page_block.book {
        width: 100%;
    }
}

.wizard-scope .loading-indicator {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9997
}

.wizard-scope .loading-indicator.enabled {
    display: block
}

.wizard-scope .loading-indicator .indicator {
    position: absolute;
    top: 60px;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    text-align: center;
    z-index: 9999
}

.wizard-scope .loading-indicator .indicator .indicator-icon {
    color: var(--w3-booking-wizard-primary-color);
    display: inline-block;
    width: 100%;
    margin-bottom: 16px
}

.wizard-scope .wizard-app .gm-style-iw,.wizard-scope .wizard-app .gm-style-iw-c {
    color: #000;
    font-size: 18px;
    font-weight: 700
}

.wizard-scope .wizard-app h1,.wizard-scope .wizard-app h2,.wizard-scope .wizard-app h3 {
    color: #fff
}

.wizard-scope .wizard-app .notify-popup {
    position: fixed;
    left: 15%;
    top: 15%;
    right: 15%;
    background: #fff;
    min-height: 400px;
    z-index: 999999;
    border-radius: 25px;
    border: 1px solid #000;
    box-shadow: 2px 2px 5px #666;
    padding: 95px 25px 25px 25px
}

.wizard-scope .wizard-app .notify-popup .action {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 18px;
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer
}

.wizard-scope .wizard-app .notify-popup .action:hover {
    color: #666
}

.wizard-scope .wizard-app .notify-popup .action span,.wizard-scope .wizard-app .notify-popup .action svg {
    width: 48px;
    height: 48px;
    display: inline-block
}

.wizard-scope .wizard-app .notify-popup .action span {
    width: auto;
    line-height: 48px;
    font-weight: 700;
    margin-right: 8px
}

.wizard-scope .wizard-app .notify-popup,.wizard-scope .wizard-app .notify-popup h1,.wizard-scope .wizard-app .notify-popup h2,.wizard-scope .wizard-app .notify-popup h3 {
    color: #000!important
}

.wizard-scope .wizard-app .widget-receipt .personaldata {
    font-weight: 700
}

.wizard-scope .wizard-app .widget-receipt table {
    width: 100%;
    margin-bottom: 40px
}

.wizard-scope .wizard-app .widget-receipt table tr.spacer td {
    height: 24px
}

.wizard-scope .wizard-app .widget-receipt table td.table-size {
    width: 500px
}

.wizard-scope .wizard-app .widget-receipt table tr.last td {
    padding-bottom: 15px
}

.wizard-scope .wizard-app .widget-receipt table tr.total td {
    padding-top: 15px;
    border-top: 1px solid #fff
}

.wizard-scope .wizard-app .widget-receipt table tr.total td.final {
    font-weight: 700
}

.wizard-scope .wizard-app.generic .widget-form .widget-payment-issuer select.sm,.wizard-scope .wizard-app.generic .widget-payment-issuer .widget-form select.sm,.wizard-scope .wizard-app .widget-form .form-control.sm {
    max-width: 200px
}

.wizard-scope .wizard-app .widget-form .required-notice {
    font-weight: 700
}

.wizard-scope .wizard-app .widget-form .form-group>div {
    position: relative
}

.wizard-scope .wizard-app .widget-form .form-group input,.wizard-scope .wizard-app .widget-form .form-group select {
    display: inline-block
}

.wizard-scope .wizard-app .widget-form .form-error .form-required {
    color: #9b2953
}

.wizard-scope .wizard-app .widget-form .form-error input::-moz-placeholder {
    color: #9b2953;
    font-weight: 400;
    font-style: italic
}

.wizard-scope .wizard-app .widget-form .form-error input::placeholder {
    color: #9b2953;
    font-weight: 400;
    font-style: italic
}

.wizard-scope .wizard-app .widget-form .form-error label {
    font-weight: 700
}

.wizard-scope .wizard-app .widget-form .form-error input,.wizard-scope .wizard-app .widget-form .form-error select {
    border-width: 3px;
    border-color: #9b2953;
    background: #fff!important
}

.wizard-scope .wizard-app .btn-circle.btn-xl {
    width: 65px;
    height: 65px;
    line-height: 65px;
    padding: 0;
    margin: 0;
    border-radius: 35px;
    font-size: 24px
}

.wizard-scope .wizard-app .btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    line-height: 1.42857
}

.wizard-scope .wizard-app .btn-circle.disabled {
    opacity: .35
}

.wizard-scope .wizard-app .step {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    transition: opacity 1s linear;
    visibility: hidden
}

.wizard-scope .wizard-app .step.active {
    visibility: visible;
    opacity: 1
}

.wizard-scope .wizard-app .step .buttons .button {
    margin-bottom: 25px
}

.wizard-scope .wizard-app .progress-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    opacity: .8
}

.wizard-scope .wizard-app .progress-bar .bar {
    background: var(--w3-booking-wizard-secondary-color);
    width: 0;
    height: 100%
}

.wizard-scope .wizard-app .number-toggle {
    display: flex;
    margin-bottom: 25px
}

.wizard-scope .wizard-app .number-toggle.pets {
    margin-top: 50px
}

.wizard-scope .wizard-app .number-toggle>.label {
    width: 300px;
    line-height: 65px
}

.wizard-scope .wizard-app .number-toggle>.handle {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    text-align: center
}

.wizard-scope .wizard-app .number-toggle>.handle>* {
    margin-left: 6px;
    margin-right: 6px
}

.wizard-scope .wizard-app .number-toggle>.handle>.handle-value {
    width: 45px;
    line-height: 65px;
    text-align: center
}

.wizard-scope.wizard-scope {
    min-height: 100vh
}

.wizard-scope.wizard-scope .loading-error {
    max-width: 80%;
    width: 650px;
    margin: 25px auto
}

.wizard-scope.wizard-scope .navbar.steps .circle {
    font-family: inherit;
    line-height: 25px
}

.wizard-scope.wizard-scope .navbar.steps .navbar-nav li {
    box-shadow: none
}

.wizard-scope .wizard-app.generic {
    position: relative;
    overflow-y: hidden;
    font-size: 1rem;
    padding-bottom: 0
}

.wizard-scope .wizard-app.generic main.main>section {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%
}

.wizard-scope .wizard-app.generic main.main>section .step-wrapper {
    flex: 1
}

.wizard-scope .wizard-app.generic main.main>section .step-wrapper .step {
    margin: 0 auto;
    max-width: 650px
}

.wizard-scope .wizard-app.generic main.main>section .navbar.steps .navbar-nav li {
    min-width: 110px
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper {
    box-shadow: -8px 0 8px 0 rgba(0,0,0,.1);
    width: 420px;
    background: #fff;
    opacity: 1;
    transform: translateY(0)
}

.wizard-scope .wizard-app.generic .loading-indicator.enabled .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: .6
}

.wizard-scope .wizard-app.generic .loading-indicator.enabled .indicator {
    top: 135px
}

.wizard-scope .wizard-app.generic .loading-indicator.enabled .indicator span {
    color: #fff!important
}

.wizard-scope .wizard-app.generic .loading-indicator .v-spinner .v-clip {
    border-color: var(--w3-booking-wizard-primary-color) var(--w3-booking-wizard-primary-color) transparent!important
}

.wizard-scope .wizard-app.generic .slotdata *,.wizard-scope .wizard-app.generic .slotdata h1,.wizard-scope .wizard-app.generic .slotdata h2 {
    color: #fff
}

.wizard-scope .wizard-app.generic .slotdata h1,.wizard-scope .wizard-app.generic .slotdata h2 {
    text-transform: uppercase;
    margin: 0;
    padding: 0
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head {
    position: relative;
    z-index: 100;
    padding: 8px;
    // height: 100px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    background: var(--w3-booking-wizard-secondary-color);
    background: linear-gradient(90deg,var(--w3-booking-wizard-secondary-color),var(--w3-booking-wizard-primary-color))
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head>* {
    margin-right: 15px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head h1 {
    font-size: 40px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .arrow {
    max-width: 90px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .arrow svg {
    width: auto;
    height: 40px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .logo {
    max-width: 250px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .info {
    height: 38px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .info span {
    display: block;
    font-size: 14px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .info h2 {
    font-size: 16px
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-receipt-head {
    padding: 8px 20px;
    height: 100px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    background: var(--w3-booking-wizard-primary-color);
    border-left: 1px solid #fff
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-receipt-head h2 {
    font-size: 22px;
    margin-bottom: 10px
}

.wizard-scope .wizard-app.generic .progress-bar {
    z-index: 9999;
    background: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic,.wizard-scope .wizard-app.generic * {
    color: #000;
    font-family: Rubik,sans-serif
}

.wizard-scope .wizard-app.generic a {
    color: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic small {
    font-size: -1rem
}

.wizard-scope .wizard-app.generic h3 {
    font-size: 18px;
    color: var(--w3-booking-wizard-primary-color);
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 25px
}

.wizard-scope .wizard-app.generic .main .container {
    max-width: 650px
}

.wizard-scope .wizard-app.generic .step {
    position: relative;
    padding: 15px;
    z-index: 101
}

.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-] {
    margin: 0 0 .5rem
}

.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-].form-error label {
    color: #d80012
}

.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-]:not(.form-field-type-number-range) label {
    margin-top: .5rem
}

.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-]:not(.form-field-type-number-range) input,.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-]:not(.form-field-type-number-range) select {
    border: 1px solid #000
}

.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-]:not(.form-field-type-number-range).form-error input {
    border: 1px solid #d80012
}

.wizard-scope .wizard-app.generic .step .form-group div[class*=form-field-type-] label {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: normal
}

.wizard-scope .wizard-app.generic .receipt-small {
    position: relative;
    transition: opacity 1.5s ease-in;
    display: none
}

.wizard-scope .wizard-app.generic .receipt-large {
    display: block;
    transition: all 1s ease-out
}

.wizard-scope .wizard-app.generic .action-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 15px;
    padding-bottom: 25px
}

.wizard-scope .wizard-app.generic .action-bar .btn {
    padding: 6px 25px;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    min-width: auto;
    display: inline-block;
    height: 70px;
    width: 175px;
    line-height: 60px
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-previous {
    background: none;
    color: var(--w3-booking-wizard-secondary-color);
    padding-left: 0;
    width: auto!important;
    text-decoration: underline
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-next {
    background: var(--w3-booking-wizard-primary-color);
    color: #fff;
    box-shadow: 3px 3px 3px 0 rgba(0,0,0,.5);
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 1.2rem
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-next[disabled] {
    opacity: .5;
    cursor: not-allowed
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-done {
    background: var(--w3-booking-wizard-primary-color);
    color: #fff
}

.wizard-scope .wizard-app.generic .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    vertical-align: middle;
    text-align: center;
    z-index: 110
}

.wizard-scope .wizard-app.generic .loader .overlay {
    background: #333;
    opacity: .4;
    height: 100%;
    min-height: 50px
}

.wizard-scope .wizard-app.generic .loader .loader-icon {
    position: absolute;
    width: 60px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -15px
}

.wizard-scope .wizard-app.generic .navbar.steps {
    position: relative;
    padding: 40px 0;
    z-index: 100
}

.wizard-scope .wizard-app.generic .navbar.steps .navbar-nav {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
    list-style-type: none
}

.wizard-scope .wizard-app.generic .navbar.steps li {
    position: relative;
    flex: inherit;
    font-size: 1rem
}

.wizard-scope .wizard-app.generic .navbar.steps li,.wizard-scope .wizard-app.generic .navbar.steps li.btn,.wizard-scope .wizard-app.generic .navbar.steps li.btn-primary {
    background: none;
    border: none;
    padding: 0
}

.wizard-scope .wizard-app.generic .navbar.steps li .circle {
    display: inline-block;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 1.6rem;
    font-size: 14px
}

.wizard-scope .wizard-app.generic .navbar.steps li .label {
    width: 100%;
    display: block;
    font-size: 14px;
    padding-top: 5px
}

.wizard-scope .wizard-app.generic .navbar.steps li a {
    display: block;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    text-align: center;
    font-size: 1rem
}

.wizard-scope .wizard-app.generic .navbar.steps li.done a .label {
    color: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.done .circle,.wizard-scope .wizard-app.generic .navbar.steps li.done:before {
    background: #000
}

.wizard-scope .wizard-app.generic .navbar.steps li.active a .label {
    color: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.active .circle {
    color: var(--w3-booking-wizard-primary-color);
    background: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.active:before {
    background: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.todo a .label {
    color: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.todo .circle {
    color: var(--w3-booking-wizard-secondary-color);
    background: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.todo:before {
    background: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li.todo,.wizard-scope .wizard-app.generic .navbar.steps li.todo a {
    cursor: default
}

.wizard-scope .wizard-app.generic .navbar.steps li:before {
    position: absolute;
    top: 1.32rem;
    left: -50%;
    display: block;
    width: 100%;
    height: 1px;
    content: "";
    z-index: -1
}

.wizard-scope .wizard-app.generic .navbar.steps li:first-child:before {
    display: none
}

.wizard-scope .wizard-app.generic .number-range {
    width: auto;
    margin-left: auto
}

.wizard-scope .wizard-app.generic .number-range input {
    margin: 0 5px;
    width: 40px;
    max-width: 40px;
    text-align: center;
    height: 28px;
    padding: 0;
    background: transparent;
    border: none;
    font-weight: 700
}

.wizard-scope .wizard-app.generic .number-range.value-0 input {
    font-weight: 400
}

.wizard-scope .wizard-app.generic .number-range a {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    background: var(--w3-booking-wizard-primary-color);
    border-radius: 28px;
    line-height: 27px;
    text-align: center;
    cursor: pointer
}

.wizard-scope .wizard-app.generic .number-range a,.wizard-scope .wizard-app.generic .number-range a:hover {
    color: #fff
}

.wizard-scope .wizard-app.generic .number-range a:hover {
    background: hsla(var(--w3-booking-wizard-primary-color-h),var(--w3-booking-wizard-primary-color-s),calc(var(--w3-booking-wizard-primary-color-l) + 20%),var(--w3-booking-wizard-primary-color-a,1))
}

.wizard-scope .wizard-app.generic .number-range a.disabled,.wizard-scope .wizard-app.generic .number-range a.disabled:hover {
    opacity: .7;
    cursor: default;
    background: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .receipt-small {
    align-self: flex-end;
    width: 100%;
    font-size: .7em;
    background: var(--w3-booking-wizard-primary-color);
    height: 50px;
    z-index: 99;
    margin: 25px 0 0 0
}

.wizard-scope .wizard-app.generic .receipt-small .receipt-small-timer {
    margin-top: 10px;
    width: 100%
}

.wizard-scope .wizard-app.generic .receipt-small .receipt-small-timer .receipt-small-timer-icon {
    padding-right: 5px
}

.wizard-scope .wizard-app.generic .receipt-small,.wizard-scope .wizard-app.generic .receipt-small * {
    color: #fff;
    font-weight: 700
}

.wizard-scope .wizard-app.generic .receipt-small * {
    margin: 0
}

.wizard-scope .wizard-app.generic .receipt-small>div {
    vertical-align: top;
    height: 40px;
    line-height: 40px
}

.wizard-scope .wizard-app.generic .receipt-small .receipt-small-content {
    padding: 0 25px;
    display: flex
}

.wizard-scope .wizard-app.generic .receipt-small .receipt-small-content .details {
    width: 60%;
    line-height: 20px
}

.wizard-scope .wizard-app.generic .receipt-small .receipt-small-content .total {
    width: 40%;
    font-size: 1.3em;
    text-align: right;
    line-height: 20px
}

.wizard-scope .wizard-app.generic .receipt-small .receipt-small-content p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.wizard-scope .wizard-app.generic .close {
    display: none;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100
}

.wizard-scope .wizard-app.generic .receipt-large-wrapper {
    border-radius: 0;
    border: none;
    padding: 0;
    z-index: 99;
    position: relative
}

.wizard-scope .wizard-app.generic .receipt-large {
    border: none;
    background: #fff;
    font-size: 1rem;
    padding: 0;
    border-radius: unset
}

.wizard-scope .wizard-app.generic .receipt-large .receipt-large-timer {
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 16px
}

.wizard-scope .wizard-app.generic .receipt-large .receipt-large-timer .receipt-large-timer-icon {
    padding-right: 5px
}

.wizard-scope .wizard-app.generic .receipt-large .list-group {
    padding: 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group .list-group-item.image {
    padding: 0;
    margin: 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group .list-group-item.image img {
    width: 100%;
    margin: 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li,.wizard-scope .wizard-app.generic .receipt-large .list-group ul {
    margin: 0;
    padding: 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li {
    background: none;
    border: none;
    padding: 4px 16px;
    list-style-type: none
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.highlight .label {
    font-weight: 700
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.highlight * {
    color: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.value-large>div,.wizard-scope .wizard-app.generic .receipt-large .list-group li.value-medium>div,.wizard-scope .wizard-app.generic .receipt-large .list-group li.value-small>div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.value-large>div {
    margin-top: 15px;
    font-weight: 700
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.value-small>div>:first-child {
    flex-grow: 1
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.value-small>div>:last-child {
    width: 85px;
    text-align: right;
    flex-grow: 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.separator>div {
    height: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.group {
    font-weight: 700
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.total {
    margin-top: 20px;
    padding-top: 10px
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.total:before {
    content: "";
    border-top: 1px solid var(--w3-booking-wizard-secondary-color);
    display: block;
    padding: 10px 0 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group li.total .value {
    font-weight: 700;
    color: var(--w3-booking-wizard-primary-color);
    font-size: 1.125rem
}

.wizard-scope .wizard-app.generic .receipt-large .list-group .title h3 {
    font-size: 22px;
    color: var(--w3-booking-wizard-primary-color);
    margin: 0
}

.wizard-scope .wizard-app.generic .receipt-large .list-group .title .subtitle {
    font-weight: 700
}

.wizard-scope .wizard-app.generic .receipt-large .list-group .group .label {
    font-size: 18px;
    color: var(--w3-booking-wizard-primary-color);
    margin: 0
}

.wizard-scope .wizard-app.generic .title label {
    margin-right: 10px
}

.wizard-scope .wizard-app.generic div.separator {
    border-top: 1px solid var(--w3-booking-wizard-primary-color);
    height: 30px;
    margin-top: 30px
}

.wizard-scope .wizard-app.generic .info-guide {
    display: inline-block;
    font-size: .8em;
    margin-bottom: 35px
}

.wizard-scope .wizard-app.generic .form-field-type-birthdate .field-inputs input {
    width: 30%
}

.wizard-scope .wizard-app.generic .form-field-type-birthdate .field-inputs .input-seperator {
    display: inline-block;
    text-align: center;
    width: 5%
}

.wizard-scope .wizard-app.generic .form-field-type-birthdate .info {
    font-size: .8em;
    padding: 15px 0 0 0;
    color: #595959
}

.wizard-scope .wizard-app.generic .form-field-type-boolean {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 35px
}

.wizard-scope .wizard-app.generic .form-field-type-boolean .toggle-button {
    width: 125px;
    max-width: 125px
}

.wizard-scope .wizard-app.generic .form-field-type-boolean .info-guide {
    margin-bottom: 0
}

.wizard-scope .wizard-app.generic .help-guide {
    display: inline-block;
    font-size: .8em;
    margin-left: 8px
}

.wizard-scope .wizard-app.generic .help-guide,.wizard-scope .wizard-app.generic .help-guide path,.wizard-scope .wizard-app.generic .help-guide svg {
    color: var(--w3-booking-wizard-secondary-color);
    fill: currentColor
}

.wizard-scope .wizard-app.generic .help-guide .popover {
    display: none
}

.wizard-scope .wizard-app.generic .help-guide:hover .popover {
    z-index: 999999;
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -150px;
    top: 25px;
    width: 300px;
    padding: 15px;
    background: #fff;
    border: 1px solid var(--w3-booking-wizard-primary-color);
    border-radius: 8px;
    box-shadow: 3px 3px 3px 0 rgba(0,0,0,.5)
}

.wizard-scope .wizard-app.generic .form-field-type-boolean,.wizard-scope .wizard-app.generic .form-field-type-number-range {
    width: 100%
}

.wizard-scope .wizard-app.generic .form-field-type-boolean>div,.wizard-scope .wizard-app.generic .form-field-type-number-range>div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between
}

.wizard-scope .wizard-app.generic .form-field-type-boolean label,.wizard-scope .wizard-app.generic .form-field-type-number-range label {
    display: block
}

.wizard-scope .wizard-app.generic .variable-field {
    min-height: 55px
}

.wizard-scope .wizard-app.generic .variable-field>label {
    float: left
}

.wizard-scope .wizard-app.generic .variable-field.number-range {
    margin-left: -.75rem
}

.wizard-scope .wizard-app.generic .variable-field.boolean,.wizard-scope .wizard-app.generic .variable-field.number-range {
    border: none
}

.wizard-scope .wizard-app.generic .variable-field.birthdate {
    padding: 0;
    background: none;
    border: none;
    width: 100%
}

.wizard-scope .wizard-app.generic .variable-field.birthdate input {
    background: #fff
}

.wizard-scope .wizard-app.generic .variable-field.radio {
    display: inline;
    margin-right: 5px
}

.wizard-scope .wizard-app.generic .variable-field.variable-field-radio label {
    float: none!important
}

.wizard-scope .wizard-app.generic .vdp-datepicker__calendar {
    bottom: 8px
}

.wizard-scope .wizard-app.generic label.vue-js-switch {
    padding: 0;
    margin-right: 15px;
    height: auto;
    width: auto;
    display: inline-block
}

.wizard-scope .wizard-app.generic label.vue-js-switch * {
    font-size: .8em;
    color: #fff
}

.wizard-scope .wizard-app.generic label.vue-js-switch .v-switch-core {
    background-color: #999!important
}

.wizard-scope .wizard-app.generic label.vue-js-switch.toggled .v-switch-core {
    background-color: var(--w3-booking-wizard-primary-color)!important
}

.wizard-scope .wizard-app.generic label span.help {
    display: inline-block;
    font-size: 1rem;
    cursor: pointer
}

.wizard-scope .wizard-app.generic label span.help i {
    color: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic label .info {
    display: block;
    font-size: .9em
}

.wizard-scope .wizard-app.generic .variable-field,.wizard-scope .wizard-app.generic .variable-field>label.vue-js-switch {
    min-height: auto
}

.wizard-scope .wizard-app.generic .variable-field>label:not(.vue-js-switch) {
    float: left
}

.wizard-scope .wizard-app.generic .variable-field>div.variable-field.number-range {
    float: right
}

@media (max-width: 1000px) {
    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .arrow svg {
        height:32px
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .logo img {
        width: 36px;
        height: 36px
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .title h1 {
        font-size: 36px
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .info h2 {
        font-size: 14px
    }
}

@media (max-width: 800px) {
    .wizard-scope.wizard-scope main.main>section {
        display:flex!important;
        justify-content: space-between!important;
        flex-direction: column!important
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head>* {
        margin-right: 8px
    }

    .wizard-scope.wizard-scope main.main>section .navbar.steps .navbar-nav {
        justify-content: flex-start;
        margin: 0 auto;
        width: auto
    }

    .wizard-scope.wizard-scope main.main>section .navbar.steps .navbar-nav li {
        width: 40px
    }

    .wizard-scope.wizard-scope main.main>section .navbar.steps .navbar-nav li a {
        padding-left: 1.2rem;
        padding-right: 1.2rem
    }

    .wizard-scope.wizard-scope main.main>section .receipt-small {
        display: block
    }

    .wizard-scope.wizard-scope main.main>section .receipt-large-wrapper {
        width: 100%!important;
        height: 100vh;
        margin: 0;
        padding: 0;
        position: fixed!important;
        bottom: 0
    }

    .wizard-scope.wizard-scope main.main>section .receipt-large-wrapper .receipt-large {
        opacity: 0;
        transform: translateY(100%)
    }

    .wizard-scope.wizard-scope main.main>section .receipt-large-wrapper .receipt-large .slotdata-receipt-head {
        border-left: none
    }

    .wizard-scope.wizard-scope main.main>section .receipt-large-wrapper .receipt-large .list-group-item.image {
        display: none
    }

    .wizard-scope.wizard-scope.show-receipt {
        overflow: hidden
    }

    .wizard-scope.wizard-scope.show-receipt .close {
        display: block!important
    }

    .wizard-scope.wizard-scope.show-receipt .receipt-small {
        opacity: 0
    }

    .wizard-scope.wizard-scope.show-receipt .receipt-large-wrapper {
        background: none!important;
        z-index: 102
    }

    .wizard-scope.wizard-scope.show-receipt .receipt-large-wrapper .receipt-large {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 1!important;
        transform: translateY(0)!important;
        overflow-y: scroll
    }
}

@media (max-width: 600px) {
    .wizard-scope.wizard-scope main.main>section {
        display:flex;
        justify-content: space-between;
        flex-direction: column
    }

    .wizard-scope.wizard-scope main.main>section .navbar.steps .navbar-nav li {
        min-width: auto!important;
        width: 40px
    }

    .wizard-scope.wizard-scope main.main>section .navbar.steps .navbar-nav li .label {
        display: none
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .arrow svg {
        height: 32px
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .logo img {
        width: 32px;
        height: 32px
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .title h1 {
        font-size: 24px
    }

    .wizard-scope.wizard-scope main.main>section .slotdata.slotdata-head .info h2 {
        font-size: 12px
    }
}

:root {
    --w3-booking-wizard-primary-color: unset;
    --w3-booking-wizard-secondary-color: unset
}

.wizard-scope .wizard-app.generic * {
    color: unset;
    font-family: unset
}

.wizard-scope .wizard-app.generic {
    color: #000;
    font-family: Exo,sans-serif
}

.wizard-scope .wizard-app.generic h1,.wizard-scope .wizard-app.generic h2,.wizard-scope .wizard-app.generic h3,.wizard-scope .wizard-app.generic h4,.wizard-scope .wizard-app.generic h5,.wizard-scope .wizard-app.generic h6 {
    font-weight: 700
}

.wizard-scope .wizard-app.generic h3 {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    text-transform: none;
    margin: 1rem 0
}

.wizard-scope .wizard-app.generic div.separator {
    border-top: 1px solid var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .receipt-small {
    display: none!important;
    height: 100px;
    font-size: 1rem;
    padding-top: 10px;
    padding-bottom: 10px
}

@media (min-width: 1200px) {
    .wizard-scope .wizard-app.generic .wizard .step .layout-row:first-of-type h3 {
        font-size:36px;
        font-weight: 500
    }
}

.wizard-scope .wizard-app.generic .wizard .step .form-field-type-boolean .toggle-button {
    width: auto;
    max-width: none;
    margin-right: 30px
}

.wizard-scope .wizard-app.generic .wizard .step .form-field-type-boolean .toggle-button label {
    margin-top: 0!important
}

.wizard-scope .wizard-app.generic .wizard .step .form-field-type-boolean label {
    display: block;
    margin: 0;
    font-weight: 600
}

.wizard-scope .wizard-app.generic .wizard .step .form-field-type-boolean .info-guide {
    margin-bottom: 0
}

.wizard-scope .wizard-app.generic .wizard .step .form-field-type-number-range label {
    font-weight: 600
}

.wizard-scope .wizard-app.generic .wizard .step .form-field-type-number-range .info-guide {
    margin-bottom: 0
}

.wizard-scope .wizard-app.generic .slotdata *,.wizard-scope .wizard-app.generic .slotdata h1,.wizard-scope .wizard-app.generic .slotdata h2 {
    color: inherit
}

.wizard-scope .wizard-app.generic .slotdata h1,.wizard-scope .wizard-app.generic .slotdata h2 {
    text-transform: none
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head {
    background: none
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .arrow svg {
    height: 20px
}

@media (min-width: 1200px) {
    .wizard-scope .wizard-app.generic .slotdata.slotdata-head .logo {
        width: 100%;
    }
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .logo img {
    width: 100%!important;
    height: auto!important
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-head .title {
    line-height: 1;
    display: none;
}

.wizard-scope .wizard-app.generic .slotdata.slotdata-receipt-head {
    border-left: none;
    background: none
}

.wizard-scope .wizard-app.generic main.main>section {
    max-width: 1140px;
    margin: 0 auto
}

.wizard-scope .wizard-app.generic main.main>section .step-wrapper .step {
    max-width: none;
    margin: 0
}

@media (min-width: 768px) {
    .wizard-scope .wizard-app.generic main.main>section .step-wrapper .step {
        margin-right:30px
    }
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper {
    box-shadow: none;
    background: none
}

@media (max-width: 800px) {
    .wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper {
        position:static!important;
        height: auto
    }
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper * {
    color: #fff
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large {
    height: auto!important;
    margin-top: 100px;
    padding: 10px 0;
    background: var(--w3-booking-wizard-primary-color);
    border-radius: 10px;
    color: #fff
}

@media (max-width: 800px) {
    .wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large {
        margin-top:0;
        opacity: unset;
        transform: unset
    }
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .slotdata.slotdata-receipt-head {
    height: auto
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .slotdata.slotdata-receipt-head h2 {
    font-size: 24px;
    font-weight: 300
}

@media (min-width: 1200px) {
    .wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .slotdata.slotdata-receipt-head h2 {
        font-size:36px
    }
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .list-group .list-group-item {
    padding: 4px 20px
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .list-group .list-group-item.highlight *,.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .list-group .list-group-item.label *,.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .list-group .list-group-item.value-small * {
    color: hsla(0,0%,100%,.9)
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .list-group .list-group-item.value-large:not(:first-of-type)>div {
    margin-top: 0
}

.wizard-scope .wizard-app.generic main.main>section .receipt-large-wrapper .receipt-large .list-group .list-group-item.separator div {
    border-bottom-width: 1px;
    border-bottom-color: #fff
}

.wizard-scope .wizard-app.generic .navbar.steps {
    padding-top: 0
}

.wizard-scope .wizard-app.generic .navbar.steps li .circle {
    width: 24px;
    height: 24px;
    color: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .navbar.steps li a .label {
    font-size: 12px
}

.wizard-scope .wizard-app.generic .navbar.steps li.done .circle {
    color: var(--w3-booking-wizard-primary-color);
    background: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .number-range a {
    font-size: 24px;
    text-decoration: none
}

.wizard-scope .wizard-app.generic .number-range a:hover {
    background: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .widget-payment-method {
    margin-bottom: 10px
}

.wizard-scope .wizard-app.generic .widget-payment-method label {
    margin: 0 0 0 5px
}

.wizard-scope .wizard-app.generic .widget-payment-method .payment-terms-list {
    margin-top: 1rem
}

.wizard-scope .wizard-app.generic .widget-payment-method-content-description {
    margin: 5px 0 0 18px
}

.wizard-scope .wizard-app.generic .widget-payment-service {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #5cb1da
}

.wizard-scope .wizard-app.generic .widget-payment-service-item {
    margin: 10px 15px 10px 0
}

.wizard-scope .wizard-app.generic .widget-payment-service-item label {
    margin: 0 0 0 5px
}

.wizard-scope .wizard-app.generic .widget-payment-service-item label img {
    width: auto;
    max-width: 100px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .widget-maps-preference-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.wizard-scope .wizard-app.generic .widget-maps-preference .widget-maps-preference-actions .btn {
    margin-bottom: 5px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .btn {
    padding: .75rem;
    border-radius: 5px;
    background: #d80012;
    border-color: #d80012;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase
}

.wizard-scope .wizard-app.generic .widget-maps-preference .btn:hover {
    background: var(--w3-booking-wizard-primary-color);
    border-color: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .map-legend {
    display: flex;
    justify-content: center;
    margin: 15px 0
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .map-legend-item {
    margin: 0 15px;
    text-align: center
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .map-legend-item-icon {
    width: 30px;
    margin: 0 auto 5px auto
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .map-legend-item-icon img {
    max-width: 100%;
    height: auto
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .map-legend-item-label {
    font-size: 13px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map {
    position: relative;
    overflow: hidden
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map #location-map {
    border-radius: 2px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info {
    position: absolute;
    left: 10px;
    right: 60px;
    bottom: 0;
    padding: 15px;
    border-radius: 2px 2px 0 0;
    background: var(--w3-booking-wizard-secondary-color);
    box-shadow: 0 1px 4px -1px rgba(0,0,0,.3);
    color: #fff
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info.closed {
    top: 100%;
    height: 0
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info.no-focus {
    top: 70%;
    height: 30%;
    overflow: hidden
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info .open-handle {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 10px;
    margin: 0 auto;
    padding: 4px;
    border-radius: 0 0 2px 2px;
    transition: .5s;
    cursor: pointer
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info .open-handle span {
    display: block;
    height: 2px;
    background: #fff
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info .open-handle:hover {
    background: var(--w3-booking-wizard-primary-color)
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info .close-handle {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--w3-booking-wizard-primary-color);
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    transition: .5s;
    cursor: pointer
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container,.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info .close-handle:hover {
    color: #fff
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-title {
    margin-bottom: .5rem;
    font-size: 18px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-gallery {
    display: flex;
    margin: 0 -5px 10px -5px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-gallery-item {
    position: relative;
    padding: 0 5px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-gallery-item-caption {
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.5);
    font-size: 24px
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-action {
    margin-top: .5rem
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-action .btn {
    border-color: var(--w3-booking-wizard-primary-color);
    background: var(--w3-booking-wizard-primary-color);
    color: #fff
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .w3-location-map .location-map-info-container .location-map-info-action .btn:hover {
    border-color: var(--w3-booking-wizard-secondary-color);
    background: var(--w3-booking-wizard-secondary-color)
}

.wizard-scope .wizard-app.generic .widget-maps-preference .map-holder .map-holder-actions {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.wizard-scope .wizard-app.generic .action-bar .btn {
    width: auto;
    height: auto;
    padding: .75rem;
    border-radius: 5px;
    background: #d80012;
    border-color: #d80012;
    color: #fff;
    font-size: 16px!important;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-previous {
    font-size: 16px;
    padding: .75rem;
    border: 1px solid var(--w3-booking-wizard-secondary-color);
    text-decoration: none
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-previous:hover {
    border-color: var(--w3-booking-wizard-secondary-color);
    background: var(--w3-booking-wizard-secondary-color);
    color: #fff
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-next {
    font-size: 16px;
    border: 1px solid #d80012;
    background: #d80012;
    box-shadow: none
}

.wizard-scope .wizard-app.generic .action-bar .btn.btn-next:hover {
    border-color: var(--w3-booking-wizard-secondary-color);
    background: var(--w3-booking-wizard-secondary-color);
    color: #fff
}

.wizard-scope .wizard-app.generic  .layout-column.form {
    background-color: $white;
}