.c-enormail-webform {
    &__panelwrapper {
        margin-top: 3rem;
        border-radius: 20px;
        box-shadow: 0 0 20px rgba($black, .3);
    }

    &__panel {
        border-radius: 20px;
    }

    &__form {
        border-radius: 20px;
        background-color: #FFF !important;
    }

    &__button {
        background-color: var(--w3-primary-color) !important;
		border-color: var(--w3-primary-color) !important;
		&:hover {
			background-color: darken(var(--w3-primary-color), 20%) !important;
			border-color: darken(var(--w3-primary-color), 20%) !important;
		}
    }
}