.w3-vue-booking-wrapper {
    .accommodation-list {
        .accommodation-list-item {
            .list-item-content {
                border-radius: 20px;
                box-shadow: 0 0 20px rgba($black, .3);
                flex-flow: column nowrap;

                .list-item-image {
                    border-radius: 20px 20px 0 0;
                    overflow: hidden;
                    height: max-content;
                    flex: 0 0 auto !important;
                }
                .list-item-container  {
                    flex: 0 1 100% !important;
                    .list-item-body {
                        flex-grow: 1;
                        .list-item-title {
                            margin-top: 10px;
                            .list-item-link {
                                color: var(--w3-booking-widget-primary-color);
                                &:hover {
                                    color: darken(var(--w3-booking-widget-primary-color), 20%);
                                }
                            }
                        }
                        .list-item-subtitle {
                            display: none;
                        }

                        .list-item-description {
                            display: none;
                        }

                        .list-item-extra {
                            margin-top: auto;
                            .list {
                                margin-top: 10px;
                                min-height: auto;
                                padding: 0;

                                &::after {
                                    display: none;
                                }
                            }

                            .list-item-btn {
                                @extend .btn;
                                background-color: var(--w3-booking-widget-primary-color);
                                border-color: var(--w3-booking-widget-primary-color);
                                &:hover {
                                    background-color: darken(var(--w3-booking-widget-primary-color), 20%);
                                    border-color: darken(var(--w3-booking-widget-primary-color), 20%);
                                }
                            }
                        }

                        .list-item-location-address {
                            ul {
                                li {
                                    padding: 0;
                                    &::after {
                                        position: relative;
                                        right: 0;
                                        margin-right: 4px;
                                    }
                                    &.location-address-region {
                                        &::after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    
                }
                .list-item-footer {
                    border-radius: 0 0 20px 20px;
                    .list-item-availability {
                        .travel-date {
                            width: 100%;
                        }
                    }
                }
                .prices-availability {
                    background-color: var(--w3-booking-widget-primary-color);
                    border: 1px solid var(--w3-booking-widget-primary-color);

                    .discount-price {
                        .price-prefix {
                            display: inline;
                            font-weight: inherit;
                            font-size: inherit;
                            margin-right: 5px;
                        }
                    }

                    &:focus,
                    &:hover {
                        border: 1px solid darken(var(--w3-booking-widget-primary-color), 20%);
                        background-color: darken(var(--w3-booking-widget-primary-color), 20%);

                        .price-old,
                        .price {
                            color: $white;
                        }

                        .price-info {
                            .popover-price-info-reference {
                                color: $white;
                            }
                        }

                        .discount-price {
                            color: $white;

                            .price-prefix {
                                display: inline;
                                font-weight: inherit;
                                font-size: inherit;
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .matrix-btn {
                    color: var(--w3-booking-widget-secondary-color);
                    text-transform: none;
                    svg {
                        background: var(--w3-booking-widget-secondary-color);
                        color: #FFF;
                    }
                    &:hover {
                        color: darken(var(--w3-booking-widget-secondary-color), 10%);
                        svg {
                            background: darken(var(--w3-booking-widget-secondary-color), 10%);
                        }
                    }
                }
            }
        }
    }
}