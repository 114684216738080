// mini-sab
.mini-sab {
	z-index: 10;
	position: relative;
	margin-bottom: 5rem;
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			width: 100%;
			z-index: 100;
			position: relative;
			margin: 0;
			padding: 15px;
			border-radius: 20px;
			background: $primary;
			box-shadow: 0 0 20px rgba($black, 0.15);
			color: #fff;
			margin-top: -44px;

			@include media-breakpoint-down(sm) {
				padding: 40px 30px 20px;
				margin-top: -50px;
			}

			@include media-breakpoint-up(lg) {
				padding-left: 20px;
				padding-right: 20px;
			}

			.mini_search_book {
				flex: 1;

				.filters {
					margin-top: 0;
					padding: 0;
					background: none;
					box-shadow: none;
				}
			}
		}
	}
}