&.landings_page {
    p {
        img {
            padding: 1rem;
            background-color: $white;
            box-shadow: 0 0 20px rgba($black, .3);
        }
    }
}

&.landings_page,
&.landings_page_extended {
    .main {
        .container-default {
            .container-holder {
                flex-direction: column;
            }
        }
    }
}